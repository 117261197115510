.contact img{
    width: 12rem;
    height: 3.2rem;
    border: 1px solid white;
}

.contact {
    padding-bottom: 1.5rem;
    top: 0;
    position:relative;
    -webkit-transition: top 0.4s ease-out;
    -moz-transition: top 0.4s ease-out;
    -o-transition: top 0.4s ease-out;
}

.contact:hover {
    top: -5px;
}