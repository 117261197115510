#content-list {
    display: flex;
    flex-direction: row;
}

#blog-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 3.3;
    overflow:auto;
    padding-bottom: 1rem;
}

#blog-techs {
    flex: 0.7;
    border: 1px solid white;
    border-radius: 5px;
}

#blog-techs ol{
    margin-left: 0.5rem;
    padding-left: 2rem;
}

#blog-techs h3 {
    justify-content: center;
    padding-bottom: 1rem;
    text-align: center;
}

.post {
    flex: 0 1 calc(33% - 1rem);
    height: 10rem;
    padding: 0.5rem;
    border: 0.5px solid #191a1d;
}

.post h2{
    line-height: 1;
}

.post:hover {
    cursor: pointer;
    border:0.5px solid #FFFFFF;
    border-radius: 5px;
}

#blog-techs h3:hover{
    cursor: pointer;
}

#blog-techs ol li:hover{
    color: #00CED1;
    cursor: pointer;
}