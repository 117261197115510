@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200&display=swap');

* {
    padding: 0;
    margin: 0;
    background-color: #191a1d;
    font-family: 'Sans', 'Courier New', Courier, monospace;
}

.main-content {
    display: flex;
    flex-direction: column;
    padding: 3rem 3rem 5rem 3rem;
    margin-top: 6rem;
    position:absolute;
    top: 2rem;
    bottom:1rem;
    left:0px;
    right:0px;
    overflow:auto;
    color: #FFFFFF;
}

h1 {
    color: #1E90FF;
}

h2 {
    color: #00CED1;
}

h3 {
    color: #4169E1;
}

h4 {
    color: #1E90FF;
}

a {
    text-decoration: none;
}

a:hover {
    color: #1E90FF;
}