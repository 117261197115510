.formation {
    padding-bottom: 2rem;
    top: 0;
    position:relative;
    -webkit-transition: top 0.4s ease-out;
    -moz-transition: top 0.4s ease-out;
    -o-transition: top 0.4s ease-out;
}

.formation h2{
    line-height: 1;
}

.formation:hover {
    top: -5px;
}