#carousel {
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;
    align-items: center;
}

#whatsapp-button {
    width: 10rem;
    padding: 0.5rem;
    margin-top: 1rem;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    text-align: center;
    color: #1E90FF;
}

#about {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    text-align: center;
    font-size: 1.2rem;
}

#about p{
    padding-bottom: 1rem;
}

.subtitle {
    text-align: center;
    padding-bottom: 1rem;
}

#skills {
    padding-bottom: 1rem;
    justify-content: center;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    color: #FFFFFF;
}

.item-skill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-top: 0.5rem;
    top: 0;
    position:relative;
    -webkit-transition: top 0.4s ease-out;
    -moz-transition: top 0.4s ease-out;
    -o-transition: top 0.4s ease-out;
}

.item-skill img{
    width: 55px;
    margin-right: 0.8rem;
    margin-bottom: 1rem;
}

.item-skill:hover {
    top: -10px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #1E90FF;
}

#whatsapp-button:hover {
    color: black;
    background-color: #4169E1;
}