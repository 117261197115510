#link_root_path {
    margin-left: 10px;
	font-size: 24px;
    text-decoration: none;
    color: #4169E1;
}

nav {
    padding: 1rem 3rem 1rem 3rem;
	height: 5rem;
    display: flex;
    justify-content: space-between;
    position:absolute;
    top:0px;
    left:0px;
    right:0px;
    overflow:hidden;
}

nav ul {
	display: flex;
    list-style: none;
}

nav ul li {
    padding-left: 2rem;
}

nav ul li a{
    color: #FFFFFF;
}

nav h1 {
    margin-right: auto;
}

a:hover {
    color: #1E90FF;
}